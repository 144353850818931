<template>
    <div class="row">
        <div class="col">
            
            <div class="card">
                <div class="card-header">
                    <h5>Claim Details</h5>
                </div>
                <div class="card-body">

                    <div v-if="loading || !claim">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>

                    <div v-else>

                       <div v-if="claim.debtor" class="row m-b-sm">
                            <h6>Recorded Debtor</h6>

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{claim.debtor.name}}</td>
                                        <td>{{claim.debtor.address_one}}, {{claim.debtor.address_two}}, {{claim.debtor.address_three}}, {{claim.debtor.address_four}}</td>
                                    </tr>
                                </tbody>
                            </table>
                       </div>

                       <div class="row m-b-sm">
                            <h6>Claim Information</h6>

                            <p><strong>Type:</strong> {{claim.type}}</p>
                            <p><strong>Vehicle Registration:</strong> {{ claim.vehicle }}</p>

                            <table v-if="claim.fuels?.length > 0" class="table">
                                <thead>
                                    <tr>
                                        <th>Fuel Type</th>
                                        <th>Litres</th>
                                        <th>Value</th>
                                        <th>Pump</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="fuel in claim.fuels">
                                        <td>{{fuel.type}}</td>
                                        <td>{{parseFloat(fuel.litres).toFixed(2)}}</td>
                                        <td>{{parseFloat(fuel.value).toFixed(2)}}</td>
                                        <td>{{fuel.pump}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row m-b-sm">
                            <h6>Claim Documents</h6>

                            <table v-if="claim.assets?.length > 0" class="table">
                                <thead>
                                    <tr>
                                        <th>Document Type</th>
                                        <th>Filename</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="asset in claim.assets">
                                        <td>{{asset.type}}</td>
                                        <td>{{asset.filename}}</td>
                                        <td>
                                            <button @click="downloadAsset(asset)" class="btn btn-sm btn-primary">Download Document</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../services/auth-header';

export default {
    name: 'Claim',
    data(){
        return {
            id: null,
            client_id: null,
            loading: false,
            claim: null
        }
    },
    mounted(){
        this.id = this.$route.params.id;
        this.client_id = this.$route.params.client_id;
        this.getClaim();
    },
    methods: {
        getClaim(){
            this.loading = true;
            axios.get(`https://api.varsanpr.com/api/dcbl/${this.id}?client_id=${this.client_id}`, { headers: authHeader() })
            .then(response => {
                this.claim = response.data;
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error.message, error);
            })
            .finally(() => {
                this.loading = false;
            })
        },
        downloadAsset(asset){
            asset.loading = true;

            axios.get(`https://api.varsanpr.com/api/dcbl/${this.id}/asset/${asset.filename}?client_id=${this.client_id}`, { 
                headers: authHeader(),
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', asset.filename);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                this.$error(error?.response?.data?.message || error.message, error);
            })

        }
    }
}
</script>